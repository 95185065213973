import styled from "styled-components";
export const Styles = styled.div`
.error{
    min-height: 85vh;
}
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:85vh;
    text-align:center;
}

`;
