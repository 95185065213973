import styled from "styled-components";
export const Styles = styled.div`
.btn-primary {
    color: #fff;
    border: 1px solid var(--main-color);
    padding: 10px 15px;
    border-radius: 7px;
    font-size:10px;
    font-weight: 300;
    background: var(--main-color);
    cursor: pointer;
    transition:.3s;
    min-width: 80px;
}
.btn-primary:hover{
    background: transparent;
    color: var(--main-color);
    border: 1px solid var(--main-color);
}
.btn-primary.disabled{
    background: var(--light-color);
    color: #000;
    border: 1px solid var(--light-color);
}
.btn-primary .spinner-border{
  border: 2px solid var(--light-color) !important;
  border-left-color: transparent !important;
}
.btn-primary:hover .spinner-border{
  border: 2px solid var(--main-color) !important;
  border-left-color: transparent !important;
}
`;