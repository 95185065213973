import React, { useEffect, useState } from "react";
import SiteNavbar from "../../components/site/navbar/SiteNavbar";
import Footer from "../../components/site/footer/Footer";
import Sidebar from "../../components/dashboard/layout/sidebar/Sidebar";
import Navbar from "../../components/dashboard/layout/navbar/Navbar";
import { useMediaQuery } from "../../helpers/useMediaQuery";
import { Styles } from "./styles";
import { useLocation } from "react-router-dom";

const PagesProvider = ({ children }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  const pathname = location.pathname;

  const [isOpen, setIsOpen] = useState(true);

  const isDashboard = pathname.includes("/dashboard");

  useEffect(() => {
    isMobile ? setIsOpen(false) : setIsOpen(true);
  }, [isMobile]);

  return (
    <>
      {isDashboard ? (
        <Styles>
          <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
          <div className="data">
            <Sidebar isOpen={isOpen} />
            <div className={isOpen ? "main-data" : "main-data closed-sidebar"}>
              {children}
            </div>
          </div>
        </Styles>
      ) : (
        <>
          <SiteNavbar />
          <div style={{ minHeight: "85vh" }}>{children}</div>
          <Footer />
        </>
      )}
    </>
  );
};

export default PagesProvider;
