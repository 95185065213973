import { Route, Routes } from "react-router-dom";
import PagesProvider from "./pages/pagesProvider/PagesProvider";
import { useTranslation } from "react-i18next";
import ErrorPage from "./pages/site/error/ErrorPage";
import { ToastContainer } from "react-toastify";
import environment from "./environment";
import React, { Suspense } from "react";
import FallbackLoader from "./components/common/loader/FallbackLoader";
import { canPublish } from "./constants/publish";
import CanPublish from "./pages/site/canPublish/CanPublish";

// import Home from "./pages/site/home/Home";
// import ContactUs from "./pages/site/contactUs/ContactUs";
// import Projects from "./pages/site/projects/Projects";
// import ProjectDetails from "./pages/site/projectDetails/ProjectDetails";
// import AboutUs from "./pages/site/aboutUs/AboutUs";
// import PrivacyPolicy from "./pages/site/privacyPolicy/PrivacyPolicy";
// import DashboardProjects from "./pages/dashboard/projects/DashboardProjects";
// import DashboardLogin from "./pages/dashboard/auth/login/DashboardLogin";
// import AddProject from "./pages/dashboard/projects/add/AddProject";
// import UpdateProject from "./pages/dashboard/projects/update/UpdateProject";
// import ImagesProject from "./pages/dashboard/projects/images/ImagesProject";
// import AddImagesProject from "./pages/dashboard/projects/images/add/AddImagesProject";
// import DashboardHome from "./pages/dashboard/home/DashboardHome";
// const ChangePassword = lazy(() => import("./pages/changePassword/ChangePassword"));
const Cars = React.lazy(() => import("./pages/site/cars/Cars"));
const CarsDashboard = React.lazy(() => import("./pages/dashboard/cars/CarsDashboard"));
const AddCar = React.lazy(() => import("./pages/dashboard/cars/add/AddCar"));
const UpdateCar = React.lazy(() => import("./pages/dashboard/cars/update/UpdateCar"));
const ImagesCar = React.lazy(() => import("./pages/dashboard/cars/images/ImagesCar"));
const AddImagesCar = React.lazy(() => import("./pages/dashboard/cars/images/add/AddImagesCar"));
const AddProjectStep2 = React.lazy(() => import("./pages/dashboard/projects/add/AddProjectStep2"));
const UpdateProjectStep2 = React.lazy(() => import("./pages/dashboard/projects/update/UpdateProjectStep2"));
const Home = React.lazy(() => import("./pages/site/home/Home"));
const ContactUs = React.lazy(() => import("./pages/site/contactUs/ContactUs"));
const Projects = React.lazy(() => import("./pages/site/projects/Projects"));
const ProjectDetails = React.lazy(() =>
  import("./pages/site/projectDetails/ProjectDetails")
);
const AboutUs = React.lazy(() => import("./pages/site/aboutUs/AboutUs"));
const PrivacyPolicy = React.lazy(() =>
  import("./pages/site/privacyPolicy/PrivacyPolicy")
);
const DashboardProjects = React.lazy(() =>
  import("./pages/dashboard/projects/DashboardProjects")
);
const DashboardLogin = React.lazy(() =>
  import("./pages/dashboard/auth/login/DashboardLogin")
);
const AddProject = React.lazy(() =>
  import("./pages/dashboard/projects/add/AddProject")
);
const UpdateProject = React.lazy(() =>
  import("./pages/dashboard/projects/update/UpdateProject")
);
const ImagesProject = React.lazy(() =>
  import("./pages/dashboard/projects/images/ImagesProject")
);
const AddImagesProject = React.lazy(() =>
  import("./pages/dashboard/projects/images/add/AddImagesProject")
);
const DashboardHome = React.lazy(() =>
  import("./pages/dashboard/home/DashboardHome")
);


function App() {
  const { i18n } = useTranslation("common");
  const _token = localStorage.getItem(environment.TOKEN_KEY);

  return (
    <div
      dir={`${i18n.language === "ar" ? "rtl" : "ltr"}`}
      className={i18n.language === "ar" ? "ar" : "en"}
    >
      <PagesProvider>
        <Suspense fallback={<FallbackLoader />}>
          <Routes>
            <Route path="/">
              <Route index element={canPublish ? <Home /> : <CanPublish />} />
              <Route
                path="/projects"
                element={canPublish ? <Projects /> : <CanPublish />}
              />
              <Route
                path="/project/:id"
                element={canPublish ? <ProjectDetails /> : <CanPublish />}
              />
              <Route
                path="/cars"
                element={canPublish ? <Cars /> : <CanPublish />}
              />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/dashboard">
                <Route
                  index
                  element={_token ? <DashboardHome /> : <DashboardLogin />}
                />
              </Route>
              <Route path="/dashboard/projects">
                <Route
                  index
                  element={_token ? <DashboardProjects /> : <DashboardLogin />}
                />
                <Route
                  path="add"
                  element={_token ? <AddProject /> : <DashboardLogin />}
                />
                 <Route
                  path="add/2"
                  element={_token ? <AddProjectStep2 /> : <DashboardLogin />}
                />
                <Route
                  path="update/:id"
                  element={_token ? <UpdateProject /> : <DashboardLogin />}
                />
                <Route
                  path="update/2/:id"
                  element={_token ? <UpdateProjectStep2 /> : <DashboardLogin />}
                />
                <Route path="images/:id">
                  <Route
                    index
                    element={_token ? <ImagesProject /> : <DashboardLogin />}
                  />
                  <Route
                    path="add"
                    element={_token ? <AddImagesProject /> : <DashboardLogin />}
                  ></Route>
                </Route>
              </Route>
              <Route path="/dashboard/cars">
                <Route
                  index
                  element={_token ? <CarsDashboard /> : <DashboardLogin />}
                />
                <Route
                  path="add"
                  element={_token ? <AddCar /> : <DashboardLogin />}
                />
                <Route
                  path="update/:id"
                  element={_token ? <UpdateCar /> : <DashboardLogin />}
                />
                <Route path="images/:id">
                  <Route
                    index
                    element={_token ? <ImagesCar /> : <DashboardLogin />}
                  />
                  <Route
                    path="add"
                    element={_token ? <AddImagesCar /> : <DashboardLogin />}
                  ></Route>
                </Route>
              </Route>
              <Route path="/dashboard/login" element={<DashboardLogin />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </Suspense>
      </PagesProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
