import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";
const Styles = styled.div`
.main-container{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
`;
const FallbackLoader = () => {
  return (
    <Styles>
      <div className="main-container text-center">
        <ClipLoader color={"var(--main-color)"} size="90px" />
      </div>
    </Styles>
  );
};

export default FallbackLoader;
