import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import resources from "./assets/locales/resources";
import {
  I18nextProvider,
  initReactI18next,
  useTranslation,
} from "react-i18next";
import i18next from "i18next";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/styles/index.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import environment from "./environment";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import LanguageDetector from "i18next-browser-languagedetector";

const _lang = environment.LANGUAGE_KEY;
const supportedLanguages = ["en", "ar", "tr"];

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: localStorage.getItem(_lang) || "en",
    detection: {
      order: ["navigator", "localStorage"],
      caches: ["localStorage"],
    },
    supportedLngs: supportedLanguages,
    // lng: localStorage.getItem(_lang) || "en",
    resources,
  });

const Main = () => {
  const { i18n } = useTranslation();

  if (i18n.language === "ar") {
    import("bootstrap/dist/css/bootstrap.rtl.min.css");
  } else {
    import("bootstrap/dist/css/bootstrap.min.css");
  }

  useEffect(() => {
    !localStorage.getItem(_lang) &&
      localStorage.setItem(_lang, localStorage.getItem("i18nextLng"));
    if (localStorage.getItem(_lang) != localStorage.getItem("i18nextLng")) {
      i18n.changeLanguage(localStorage.getItem(_lang));
    }
  }, []);

  return (
    <React.Fragment>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <Router>
            <App />
          </Router>
        </I18nextProvider>
      </Provider>
    </React.Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Main />);
