import React from "react";
import { Styles } from "./styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const Sidebar = (props) => {
  const { i18n } = useTranslation();
  const { isOpen} = props;
  return (
    <Styles>
      <div
        className={
          (isOpen ? "sidebar " : "sidebar closed ") +
          (i18n?.language === "ar" ? "rtl" : "ltr")
        }
      >
        <div className="section">
          <div className="title">LYK Global</div>
          <Link to="/dashboard/projects" className="item">
            <i className="pi pi-shopping-cart"></i>
            <div className="name">{t("projects")}</div>
          </Link>
          <Link to="/dashboard/cars" className="item">
            <i className="pi pi-car"></i>
            <div className="name">{t("cars")}</div>
          </Link>
        </div>
      </div>
    </Styles>
  );
};

export default Sidebar;
