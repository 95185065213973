import React, { useRef, useState } from "react";
import { Styles } from "./styles";
import MainButton from "../../../common/buttons/mainButton/MainButton";
import { Menubar } from "primereact/menubar";
import { Toast } from "primereact/toast";
import LanguageSwitcher from "./language-switcher";
import { logout } from "../../../../services/auth.service";
import environment from "../../../../environment";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { t } from "i18next";
import Logo from "../../../../assets/images/logo.webp";

const Navbar = (props) => {
  const { isOpen, setIsOpen } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const _token = environment.TOKEN_KEY;
  const _refresh_token = environment.REFRESH_TOKEN_KEY;
  const menuLeft = useRef(null);
  const menuRight = useRef(null);
  const toast = useRef(null);
  const items = [
    {
      label: "Options",
      items: [
        {
          label: "Update",
          icon: "pi pi-refresh",
          command: () => {
            toast.current.show({
              severity: "success",
              summary: "Updated",
              detail: "Data Updated",
              life: 3000,
            });
          },
        },
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => {
            toast.current.show({
              severity: "warn",
              summary: "Delete",
              detail: "Data Deleted",
              life: 3000,
            });
          },
        },
      ],
    },
    {
      label: "Navigate",
      items: [
        {
          label: "React Website",
          icon: "pi pi-external-link",
          url: "https://reactjs.org/",
        },
        {
          label: "Router",
          icon: "pi pi-upload",
          command: (e) => {
            //router.push('/fileupload');
          },
        },
      ],
    },
  ];

  const loggingOut = async () => {
    localStorage.removeItem(_token);
    localStorage.removeItem(_refresh_token);
    navigate("/dashboard/login");
  };

  const start = (
    <>
      <Link to="/dashboard" className="img-container">
        <img src={Logo} loading="lazy" />
      </Link>
      <div className="bars">
        <MainButton
          buttonText={
            isOpen ? (
              <i className="pi pi-bars"></i>
            ) : (
              <i className="pi pi-bars closed"></i>
            )
          }
          buttonClick={() => setIsOpen(!isOpen)}
        />
      </div>
    </>
  );

  const end = (
    <>
      <Toast ref={toast}></Toast>
      <LanguageSwitcher />
      <MainButton
        buttonText={isLoading ? <Spinner size="sm" /> : t("logout")}
        buttonClick={loggingOut}
      />
    </>
  );

  return (
    <Styles>
      <Menubar start={start} end={end} />
    </Styles>
  );
};

export default Navbar;
