import axios from "axios";
import environment from "../environment";
import { handleError } from "./error.service";
import { refresh } from "./auth.service";

const token = localStorage.getItem(environment.TOKEN_KEY);

const getConfig = (hasAuthraization, isMultiPart = false) => {
  if (hasAuthraization)
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": isMultiPart
          ? "multipart/form-data"
          : "application/json",
      },
    };
  else
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": isMultiPart
          ? "multipart/form-data"
          : "application/json",
      },
    };
};

const performRequest = async (
  method,
  apiPath,
  data,
  hasToken,
  isMultiPart = false
) => {
  let res = {};
  const config = hasToken
    ? isMultiPart
      ? getConfig(true, true)
      : getConfig(true)
    : getConfig(false);

  try {
    const response = await axios[method](
      `${environment.API_URL}/${apiPath}`,
      data ? data : config,
      config
    );
    res.status = response.status;
    res.data = response.data;
  } catch (error) {
    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data.error === "jwt expired"
    ) {
      res.status = error.response.status;
      res.data = error.response.data;
      const refreshResponse = await refresh();
      if (refreshResponse?.status === 200) {
        localStorage.setItem("token", refreshResponse.data?.data?.token);
        localStorage.setItem(
          "refreshToken",
          refreshResponse.data?.data?.refreshToken
        );
        return performRequest(method, apiPath, data, hasToken);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
      }
    } else {
      handleError(error);
    }
  }
  return res;
};

export const GET = async (apiPath, hasToken) =>
  performRequest("get", apiPath, null, hasToken);

export const GETWithParams = async (apiPath, requestParams, hasToken) =>
  performRequest("get", apiPath, { params: requestParams }, hasToken);

export const POST = async (apiPath, requestBody, hasToken) =>
  performRequest("post", apiPath, requestBody, hasToken);

export const POSTForm = async (apiPath, requestBody, hasToken) =>
  performRequest("post", apiPath, requestBody, hasToken, true);

export const DELETE = async (apiPath, hasToken) =>
  performRequest("delete", apiPath, null, hasToken);

export const PUT = async (apiPath, requestBody, hasToken) =>
  performRequest("put", apiPath, requestBody, hasToken);

export const GetToken = token;
