import styled from "styled-components";
export const Styles = styled.div`
  footer {
    padding-top: 20px;
    color: #fff;
    background: linear-gradient(var(--secondary-color), #000);
  }
  footer .row {
    margin: 0 !important;
  }
  footer .aboutus-footer {
    text-align: start;
  }
  footer .aboutus-footer p {
    color: var(--light-color) !important;
  }
  footer .aboutus-footer .footer-brand {
    display: block;
  }
  footer .aboutus-footer .footer-brand img {
    width: 90px;
  }
  footer .aboutus-footer .footer-brand:hover img {
    opacity: 0.6;
  }
  footer a {
    color: #fff;
  }
  footer a:hover {
    color: var(--main-color);
  }
  footer ul {
    padding: 0;
  }
  .social-media {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ffffff38;
  }
  footer .company-name {
    font-size: 22px;
    font-weight: bold;
    color: var(--main-color);
  }
  footer .company-name:hover {
    opacity: 0.6;
  }
  footer .footer-contact li:last-child a {
    border-bottom: none;
  }
  footer a.center {
    align-items: center;
  }
  footer .footer-contact a {
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    gap: 7px;
    padding-bottom: 5px;
    border-bottom: 1px solid #d9d9d955;
    color: var(--light-color) !important;
  }
  footer .footer-contact a i {
    color: var(--main-color) !important;
  }
  footer .footer-contact a:hover {
    color: var(--main-color) !important;
  }
  footer .footer-contact a i::before,
  footer .footer-urls a i::before {
    width: 25px !important;
    height: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  footer iframe {
    width: 100%;
    height: 100%;
  }
  .social-media a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    transition: 0.3s;
  }
  .social-media a:hover {
    background: var(--main-color);
    color: var(--secondary-color) !important;
  }
  .link {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .nav-link {
    transition: all.3s;
  }
  .nav-link:hover {
    color: var(--light-color) !important;
  }
  footer .copyright {
    padding-bottom: 20px;
    background: #000;
    text-align: center;
  }
`;
