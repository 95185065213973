import styled from "styled-components";
export const Styles = styled.div`
  .sidebar {
    position: fixed;
    width: 300px;
    height: calc(100vh - 5rem);
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    transition: transform 0.2s, left 0.2s;
    background-color: var(--surface-overlay);
    border-radius: 0 12px 012px 0;
    padding: 1rem 1.5rem;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.02), 0 0 2px rgba(0, 0, 0, 0.05),
      0 1px 4px rgba(0, 0, 0, 0.08);
    transition: all.3s;
  }
  .sidebar.rtl{
    left: auto;
    right: 0;
  }
  .sidebar.closed {
    width: 75px;
  }
  .section {
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #c2c2c260;
  }
  .section .title {
    font-weight: bold;
    margin-bottom: 1.25rem;
    opacity: 1;
    transition: all.3s;
  }
  .section .item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-inline-start: 1rem;
    transition: all.3s;
    margin-bottom: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .section .item .title {
    opacity: 1;
    transition: opacity .3s;
  }
  .sidebar.closed .section .item {
    margin-inline: auto;
    justify-content: center;
  }
  .sidebar.closed .section .title {
    display: none;
  }
  .sidebar.closed .section .item .name {
    display: none;
  }
  @media (max-width:768px){
    .sidebar {
      width: 225px;
    }
  }
  .sidebar .theme{
    position: fixed;
    bottom:0%;
    left:0%;
    background-color: var(--surface-overlay);
    text-align: center;
    padding: 20px;
    width: 300px;
    transition: all.3s;
  }
  .sidebar.closed .theme{
    width: 75px;
  }
  
  .theme .p-togglebutton.p-highlight,.theme .p-togglebutton:hover{
    background: var(--main-color);
  }
  .theme .p-togglebutton.p-highlight .pi,.theme .p-togglebutton:hover .pi{
    color: var(--light-color);
  }
  .theme .p-togglebutton,.theme .p-togglebutton.p-highlight:hover{
    background: transparent;
    padding: 7px 5px;
    border: 1px solid var(--main-color);
    box-shadow: none !important;
    transition: all.3s;
  }
  .theme .p-togglebutton .pi,.theme .p-togglebutton.p-highlight:hover .pi{
    color: var(--main-color);
  }
`;
