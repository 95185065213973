import React from "react";
import { useTranslation } from "react-i18next";
import arabicFlaf from "../../../../../assets/images/icons/ksa.png";
import englishFlag from "../../../../../assets/images/icons/usa.png";
import turkishFlag from "../../../../../assets/images/icons/trk.png";
import { Styles } from "./styles";
import environment from "../../../../../environment";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const _lang = environment.LANGUAGE_KEY;

  return (
    <Styles>
      <div className="list-container">
        <div className="lang-list">
          <img
            src={i18n?.language === "ar" ? arabicFlaf : i18n?.language === "en" ?  englishFlag : turkishFlag}
            className="flag"
            loading="lazy"
          />
          <i className="fa-solid fa-angle-down menuArrow"></i>
          <ul
            className={
              i18n?.language === "ar"
                ? "sub-lang-menu p-0"
                : "sub-lang-menu sub-lang-menu-en p-0"
            }
          >
            <li>
              <button
                onClick={() => {
                  i18n.changeLanguage("en");
                  localStorage.setItem(_lang, "en");
                }}
              >
                <img src={englishFlag} loading="lazy" className="flag" />
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  i18n.changeLanguage("ar");
                  localStorage.setItem(_lang, "ar");
                }}
              >
                <img src={arabicFlaf} loading="lazy" className="flag" />
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  i18n.changeLanguage("tr");
                  localStorage.setItem(_lang, "tr");
                }}
              >
                <img src={turkishFlag} loading="lazy" className="flag" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Styles>
  );
};

export default LanguageSwitcher;
