import styled from "styled-components";
import Img from "../../../assets/images/home/towers.png";
export const Styles = styled.div`
  #headerSection {
    margin-top: 0;
    background-color: var(--bg-color);
    padding-bottom: 40px;
    padding-top: 119px;
    height: 85vh;
    position: relative;
    background-color: #c2c2c270;
    margin-bottom: 0px;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .data{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 80px;
    text-align: center;
  }
  .img-container {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30vh;
    width: 100%;
    background-image: url(${Img});
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: contain;
  }

  /* #headerSection .header-img {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 550px;
    height: 425px;
  }
  #headerSection .header-img img {
    width: 100%;
    height: 100%;
  }

  .header-img .text-fluid {
    position: absolute;
    background: var(--main-color);
    color: var(--secondary-color) !important;
    white-space: nowrap;
    padding: 5px 15px;
    border-radius: 5px;
  }

  .header-img .text-fluid:hover {
    color: var(--main-color) !important;
    background: var(--secondary-color);
    animation-play-state: paused;
  }

  .header-img .feature1 {
    top: 10%;
    ${(props) => (props.direction === "rtl" ? `right:5%;` : `left:5%;`)}
    animation: up-features-animation 2s infinite;
    animation-timing-function: linear;
  }

  .header-img .feature2 {
    top: 40%;
    ${(props) => (props.direction === "rtl" ? `right:85%;` : `left:85%;`)}
    animation: down-features-animation 4s infinite;
    animation-timing-function: ease-in-out;
  }

  .header-img .feature3 {
    top: 90%;
    ${(props) => (props.direction === "rtl" ? `right:40%;` : `left:40%;`)}
    animation: up-features-animation 4s infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes up-features-animation {
    0% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
    }
    5% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    10% {
      -webkit-transform: translateY(6px);
      transform: translateY(6px);
    }
    15% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
    20% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    25% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    30% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
    35% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    40% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }
    45% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    50% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    55% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    60% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }
    65% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    70% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
    75% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    80% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    85% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
    90% {
      -webkit-transform: translateY(6px);
      transform: translateY(6px);
    }
    95% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    100% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
    }
  }

  @keyframes down-features-animation {
    0% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    5% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    10% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }
    15% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    20% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
    25% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    30% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    35% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
    40% {
      -webkit-transform: translateY(6px);
      transform: translateY(6px);
    }
    45% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    50% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
    }
    55% {
      -webkit-transform: translateY(8px);
      transform: translateY(8px);
    }
    60% {
      -webkit-transform: translateY(6px);
      transform: translateY(6px);
    }
    65% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
    70% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    75% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    80% {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
    85% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
    }
    90% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
    }
    95% {
      -webkit-transform: translateY(-8px);
      transform: translateY(-8px);
    }
    100% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
  } */

  #headerSection .title {
    color: var(--main-color);
    position: relative;
    width: fit-content;
    text-align: center;
  }
  #headerSection .title::after {
    position: absolute;
    content: "";
    width: 0%;
    height: 2px;
    top: 100%;
    left: 0;
    background-color: var(--main-color);
    transition: all.3s;
  }
  #headerSection .title:hover::after {
    width: 100%;
  }
  #headerSection .description {
    font-size: 25px;
  }
  @media (max-height: 600px) {
    .data{
      margin-top:0px;
    }
  }
  @media (max-width: 768px) {
    .container {
      flex-direction: column-reverse;
    }
    #headerSection .header-img {
      justify-content: center;
      width: 300px;
      height: 250px;
    }
    .header-img .feature2 {
      top: 30%;
      ${(props) => (props.direction === "rtl" ? `right:60%;` : `left:60%;`)}
    }
    #headerSection .data {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
    }
    #headerSection .description{
      font-size: 18px;
    }
  }
`;
