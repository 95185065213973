import styled from "styled-components";
export const Styles = styled.div`
  .navbar {
    min-height: 79px;
    background: transparent;
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
  }
  .navbar.sticky{
    background-color: var(--secondary-color);
  }
  .navbar .container {
    background: transparent;
  }
  .navs{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }
  .navbar .brand {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar .navbar-brand {
    margin: 0;
    /* width: 200px;
    height: 70px; */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all.3s;
  }
  .navbar .navbar-brand a {
    /* width: 200px;
    height: 70px !important; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .navbar .navbar-brand img {
    width: 100px ;
    height: 79px;
    object-fit: contain;

  }
  /* .navbar .navbar-brand img {
    width: 100%;
    height: 100%;
  } */
  .navbar .navbar-brand:hover {
    transform: scale(1.1);
  }

  .navbar .navbar-nav {
    margin-inline-start: auto;
    background: var(--secondary-color);
    display: flex;
    flex-direction: row-reverse;
  }
  .navbar .navbar-nav.arnav {
    flex-direction: row;
  }
  .navbar .nav-link {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--secondary-color) !important;
    transition: all.3s;
  }
  .navbar.sticky .nav-link{
    color: var(--light-color) !important;
  }
  .navbar .nav-link:hover {
    transform: translateY(-3px);
    color: var(--main-color) !important;
  }
  .navbar .navbar-nav .list-container {
    margin-inline-start: 5px;
  }
  .navbar .navbar-toggler {
    color: var(--secondary-color);
    border-color: var(--secondary-color);
  }
  .navbar.sticky .navbar-toggler {
    color: var(--light-color);
    border-color: var(--light-color);
  }
  .navbar .navbar-toggler:focus {
    box-shadow: none;
  }
  .static-mobile-nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 79px;
  }
  .navbar .mobile .navbar-nav {
    /* position: absolute;
    top: 79px;
    left: 0;
    width: 100%;
    height: fit-content;
    max-height: fit-content; */
    flex-direction: column;
  }
  .fill{
    ${(props) => (props.isAr ? `width: 15px;` : props.isEn ? `width:30px;` : `width: 60px;`)};
  }
  .navbar .mobile .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 20px;
    border-top: 1px solid #e9e9e942;
  }
  .navbar .mobile.show .nav-link{
    color: var(--light-color) !important;
  }
  .navbar .mobile.show .nav-link:hover{
    color: var(--main-color) !important;
  }
  .navbar .mobile .dropdown {
    border-top: 1px solid #e9e9e942;
    padding-block: 20px;
  }
  .navbar .mobile .dropdown.show {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .navbar .nav-items {
    display: flex;
    align-items: center;
  }
`;
