import { t } from "i18next";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Styles } from "./styles";

const ErrorPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setInterval(() => {
      navigate("/");
    }, 5000);
  }, []);

  return (
    <Styles>
      <div className="error">
        <Container>
          <div className="section-title">
            {t("error_title")}! <br /> {t("page_not_found")} <br />{" "}
            {t("error_description")}{" "}
            <Link to="/">
              <div>{t("home")}</div>
            </Link>
          </div>
        </Container>
      </div>
    </Styles>
  );
};

export default ErrorPage;
