import { createSlice } from "@reduxjs/toolkit";

const ProjectSlice = createSlice({
  name: "project",
  initialState: {
    projectData: null,
    projectMainData: null,
  },
  reducers: {
    setProjectData: (state, { payload }) => {
      state.projectData = payload;
    },
    setProjectMainData: (state, { payload }) => {
      state.projectMainData = payload;
    },
  },
});
export const { setProjectData, setProjectMainData } = ProjectSlice.actions;
export default ProjectSlice.reducer;
