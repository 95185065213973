import common_ar from "./ar/common.json";
import common_en from "./en/common.json";
import common_tr from "./tr/common.json";

const resources = {
    en: {
        translation: common_en
    },
    ar: {
        translation: common_ar
    }
    ,
    tr: {
        translation: common_tr
    }
};

export default resources