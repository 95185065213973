import { configureStore } from "@reduxjs/toolkit";

import ProjectReducer from "./slices/ProjectSlice";

const toolkitReducers = {
  project: ProjectReducer,
};
const store = configureStore({
  reducer: toolkitReducers,
});
export default store;
