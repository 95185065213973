import environment from "../environment";
import { POST, PUT } from "./http.service";

const token = localStorage.getItem(environment.TOKEN_KEY);
const refreshToken = localStorage.getItem(environment.REFRESH_TOKEN_KEY);

export const login = async (requestBody) => {
  const response = await POST(`auth`, requestBody, false);
  return response;
};

export const logout = async () => {
  const response = await POST(`auth`, false, token);
  return response;
};

export const refresh = async () => {
  const response = await PUT(`auth/refresh`, { refreshToken }, false);
  return response;
};
