import React from "react";
import { Styles } from "./styles";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { t } from "i18next";
import LogoImg from "../../../assets/images/logo.webp";

const Footer = () => {
  return (
    <Styles>
      <footer>
        <Container>
          <Row>
            <Col lg={4} md={6} className="mb-4 aboutus-footer">
              <Link to="/" className="mb-2 footer-brand">
                <img src={LogoImg} className="img-fluid" alt="logo" />
                {/* LYK Group */}
              </Link>
              <p>{t("main_description")}</p>
            </Col>
            <Col lg={4} md={6} className="mb-4">
              <ul className="footer-contact">
                <li className="mb-3">
                  <h5>{t("contact_us")}</h5>
                </li>
                {/* <li className="mb-1">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://goo.gl/maps/cxzS1k5gSrv6Cai19"
                  >
                    <i className="fa-solid fa-location-dot"></i> İNÖNÜ MAH ADA
                    CD. 209 /1/3/ AKYAZI SAKARYA.
                  </a>
                </li> */}
                <li className="mb-1">
                  <a
                    target="_blank"
                    className="center"
                    rel="noreferrer"
                    href="tel:+905526010008"
                  >
                    <i className="fa-solid fa-phone"></i> {t("general_manager")}
                    +905526010008
                  </a>
                </li>
                <li className="mb-1">
                  <a
                    target="_blank"
                    className="center"
                    rel="noreferrer"
                    href="tel:+905526020008"
                  >
                    <i className="fa-solid fa-phone"></i> {t("engineering_consulting")} +905526020008
                  </a>
                </li>
                <li className="mb-1">
                  <a
                    target="_blank"
                    className="center"
                    rel="noreferrer"
                    href="mailto::TurkmnElektrik_@Hotmail.com"
                  >
                    <i className="fa-solid fa-envelope"></i>{" "}
                    TurkmnElektrik_@Hotmail.com
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={4} md={12}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d391204.1417101745!2d-102.91386954010855!3d40.002140133295995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87721496e1918bc1%3A0xf335f7128a20bab9!2z2YXZgtin2LfYudipINmI2KfYtNmG2LfZhtiMINmD2YjZhNmI2LHYp9iv2YjYjCDYp9mE2YjZhNin2YrYp9iqINin2YTZhdiq2K3Yr9ip!5e0!3m2!1sar!2sca!4v1705029847933!5m2!1sar!2sca"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="footer-map"
              ></iframe>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="social-media">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/m.cihadalhlwani?igsh=ejI1cjBwbDhkOHE3"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/message/KCGP5WAUIF36B1"
              >
                <i className="fa-brands fa-whatsapp"></i>
              </a>
              <a target="_blank" rel="noreferrer" href="https://t.me/Lykgroup">
                <i className="fa-brands fa-telegram"></i>
              </a>
            </Col>
          </Row>
          <div className="link">
            <Link className="nav-link" to="/privacy-policy">
              {t("privacy_policy")}
            </Link>
          </div>
        </Container>
        <Row>
          <Col className="copyright" lg={12}>
            © 2024 {t("copyright")} &nbsp;
            <Link className="company-name" to="/">
              LYK Group
            </Link>
          </Col>
        </Row>
      </footer>
    </Styles>
  );
};

export default Footer;
