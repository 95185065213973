import React from 'react'
import { Styles } from './styles';

let MainButton = ({ buttonText, buttonDisabled, buttonType, buttonClick }) => {
    return (
        <Styles>
            <button className={buttonDisabled?"btn btn-primary my-3 disabled":"btn btn-primary my-3"} disabled={buttonDisabled} id='main' type={buttonType} onClick={buttonClick} >{buttonText}</button>
        </Styles>
    )
};

export default MainButton