import environment from "../environment";
import { errorToaster } from "../helpers/toasterConfiguration";
const _token = environment.TOKEN_KEY;
const _refresh_token = environment.REFRESH_TOKEN_KEY;

export const handleError = (error) => {
  if (error?.response?.status === 400 || error?.response?.status === 429) {
    if (error?.response?.data?.error == "invalid signature") {
      localStorage.removeItem(_token);
      localStorage.removeItem(_refresh_token);
      window.location.href = "/dashboard/login";
    }
    error?.response?.data?.message
      ? errorToaster(error?.response?.data?.message)
      : errorToaster(error?.response?.data?.error);
  } else {
    console.log(error);
  }
};
