import styled from "styled-components";
export const Styles = styled.div`
  .data {
    position: relative;
    margin-top: 99px;
  }
  .main-data {
    margin-inline-start: 20rem;
    padding-inline-end: 2.75rem;
    width: calc(100vw - 300px);
    transition: 0.3s;
    min-height: 85vh;
  }
  .main-data.closed-sidebar {
    margin-inline-start: 6rem;
    width: calc(100vw - 75px);
  }
`;
