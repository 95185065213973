const config = {
  //LOCAL STORAGE KEYS
  TOKEN_KEY: "token",
  REFRESH_TOKEN_KEY: "refreshToken",
  LANGUAGE_KEY: "selectedLanguage",
  DIRECTION_KEY: "dir",
  
  //CONSTANT
  API_URL: "https://api.lykgroup.net",
};

const dev = {
  API_URL: "https://api.lykgroup.net",
};

const acc = {
  API_URL: "https://api.lykgroup.net",
};

const test = {
  API_URL: "https://api.lykgroup.net",
};

const envs = {
  dev,
  acc,
  test,
};
const environment = {
  ...config,
  ...envs[`${process.env.REACT_APP_ENV}`],
};

export default environment;
