import styled from "styled-components";
export const Styles = styled.div`
  .list-container {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }
  @media (max-width: 992px) {
    .list-container {
      margin-top: 0;
    }
  }
  .lang-list {
    position: relative;
    left: 0rem;
    height: 25px;
    @media (max-width: 768px) {
      height: 25px;
    }
    img {
      border: 1px solid var(--secondary-color);
    }
    i {
      color: var(--secondary-color);
    }
    ul {
      text-align: right;
      list-style: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .sticky .lang-list{
    img {
      border: 1px solid var(--light-color);
    }
    i {
      color: var(--light-color);
    }
  }
  .menuArrow {
    margin: 0 0.4rem !important;
  }
  .lang-list:hover .sub-lang-menu {
    transition: 0.5s;
    opacity: 1;
    visibility: visible;
    transform: scaleY(1) translateX(10px);
    z-index: 10000;
  }
  .lang-list:hover .sub-lang-menu.sub-lang-menu-en {
    transform: scaleY(1) translateX(-10px);
  }
  .lang-list:hover .menuArrow {
    color: var(--main-color);
  }

  .sub-lang-menu {
    position: relative;
    background: #fff;
    margin: 0;
    margin-top: 6px;
    z-index: 9999;
    box-shadow: 0px 1px 3px rgb(0, 0, 0, 0.3);
    opacity: 0;
    transform-origin: 0 0;
    transform: scaleY(0) translateX(10px);
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 10000;
    li {
      padding: 0.5rem 1rem;
      button {
        background: none;
        border: 0px;
      }
      &:hover {
        background-color: var(--secondary-color);
      }
    }
  }
  .sub-lang-menu.sub-lang-menu-en {
    transform: scaleY(0) translateX(-10px);
  }

  .flag {
    width: 30px;
    height: 20px;
  }
`;
