import styled from "styled-components";
export const Styles = styled.div`
  .p-menubar{
    height: 79px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.02), 0 0 2px rgba(0, 0, 0, 0.05),
      0 1px 4px rgba(0, 0, 0, 0.08);
    padding-inline: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  .p-menubar-button{
    display: none;
  }
  .p-menubar .p-menubar-start{
    display: flex;
    align-items: center;
  }
  .p-menubar .p-menubar-end{
    margin-inline-start: auto;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .img-container{
    height: 65px;
    width: 70px;
    margin-inline-end: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-container img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  .bars .btn{
    min-width: 50px !important;
    width: 50px !important;
  }
  .pi-bars{
    transition: all.3s;
  }
  .pi-bars.closed{
    transform: rotate(90deg);
  }
`;
