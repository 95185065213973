import React, { useEffect, useRef, useState } from "react";
import { Styles } from "./styles";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useMediaQuery } from "../../../helpers/useMediaQuery";
import LanguageSwitcher from "./language-switcher";
import i18next, { t } from "i18next";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../assets/images/logo.webp";

const SiteNavbar = () => {
  const isAr = i18next.language === "ar";
  const isEn = i18next.language === "en";
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(useMediaQuery("(max-width: 992px)"));
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const navRef = useRef(null);

  window.addEventListener("scroll", () => {
    let value = window.scrollY;
    if (value > 10) {
      if (document.getElementById("navbar")) {
        document.getElementById("navbar").classList.add("sticky");
        document.getElementById("langList").classList.add("sticky");
      }
    } else {
      if (document.getElementById("navbar")) {
        document.getElementById("navbar").classList.remove("sticky");
        document.getElementById("langList").classList.remove("sticky");
      }
    }
  });

  window.addEventListener("resize", () => {
    let value = window.scrollY;
    if (value > 10) {
      if (document.getElementById("navbar")) {
        document.getElementById("navbar").classList.add("sticky");
        document.getElementById("langList").classList.add("sticky");
      }
    } else {
      if (document.getElementById("navbar")) {
        document.getElementById("navbar").classList.remove("sticky");
        document.getElementById("langList").classList.remove("sticky");
      }
    }
  });

  const isShow = (show) => {
    let value = window.scrollY;
    if (!show && value < 10) {
      document.getElementById("navbar").classList.add("sticky");
      document.getElementById("langList").classList.add("sticky");
      setShow(true);
    } else if (show && value < 10) {
      document.getElementById("navbar").classList.remove("sticky");
      document.getElementById("langList").classList.remove("sticky");
      setShow(false);
    } else {
      setShow(false);
    }
  };

  const handleNavbarToggle = (isExpanded) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Styles isAr={isAr} isEn={isEn}>
      <Navbar
        id="navbar"
        key={isMobile ? "mobile" : "desktop"}
        expand="lg"
        expanded={expanded}
        onToggle={handleNavbarToggle}
        ref={navRef}
      >
        <Container>
          <div className={isMobile ? "static-mobile-nav" : "navs"}>
            {!isMobile && (
              <>
                <div className="fill"></div>
                <LanguageSwitcher />
                <Link className="nav-link" to="/">
                  <i className="fa-solid fa-home"></i> {t("home")}
                </Link>
                <Link className="nav-link" to="/projects">
                  <i className="fa-solid fa-city"></i> {t("projects")}
                </Link>
              </>
            )}
            <div className="brand">
              <Navbar.Brand>
                <Link to="/">
                  <img src={Logo} loading="lazy" alt="LYK group" />
                </Link>
              </Navbar.Brand>
            </div>
            {!isMobile && (
              <>
                <Link className="nav-link" to="/cars">
                  <i className="fa-solid fa-car"></i> {t("cars")}
                </Link>
                <Link className="nav-link" to="/contact-us">
                  <i className="fa-solid fa-phone"></i> {t("contact_us")}
                </Link>
                <Link className="nav-link" to="/about-us">
                  <i className="fa-solid fa-circle-info"></i> {t("about_us")}
                </Link>
              </>
            )}
            <div className="nav-items">
              {isMobile && <LanguageSwitcher />}
              <Navbar.Toggle
                aria-controls="navbarScroll"
                onClick={() => {
                  isShow(show);
                }}
              >
                <i className="fa-solid fa-bars"></i>
              </Navbar.Toggle>
            </div>
          </div>
          {isMobile && (
            <Navbar.Collapse id="navbarScroll" className={isMobile && "mobile"}>
              <Nav
                navbarScroll
                className={i18next?.language === "ar" ? "arnav" : "ennav"}
              >
                <Link className="nav-link" to="/">
                  <i className="fa-solid fa-home"></i> {t("home")}
                </Link>
                <Link className="nav-link" to="/projects">
                  <i className="fa-solid fa-city"></i> {t("projects")}
                </Link>
                <Link className="nav-link" to="/cars">
                  <i className="fa-solid fa-car"></i> {t("cars")}
                </Link>
                <Link className="nav-link" to="/contact-us">
                  <i className="fa-solid fa-phone"></i> {t("contact_us")}
                </Link>
                <Link className="nav-link" to="/about-us">
                  <i className="fa-solid fa-circle-info"></i> {t("about_us")}
                </Link>
                {!isMobile && <LanguageSwitcher />}
              </Nav>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
    </Styles>
  );
};

export default SiteNavbar;
