import React from "react";
import { Styles } from "./styles";
import i18next, { t } from "i18next";
import { Container } from "react-bootstrap";

const CanPublish = () => {
  return (
    <Styles direction={i18next.language === "ar" ? "rtl" : "ltr"}>
      <section id="headerSection">
        <Container>
          <div className="data">
            <h1 className="title">LYK Group</h1>
            <div className="description">{t("soon_description")}</div>
          </div>
        </Container>
        <div className="img-container"></div>
      </section>
    </Styles>
  );
};

export default CanPublish;
